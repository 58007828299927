import ContainerMain from "src/pages/common/ContainerMain"
import image3 from "images/Home/News/image-3.svg"
import image1 from "images/Home/News/image-1.svg"
import image2 from "images/Home/News/image-2.svg"
import image7 from "images/Home/News/image-7.svg"
import Content from "pages/common/Content"
import Slider from "react-slick/lib/slider"
import prevSliderIcon from "images/prev_slider_incon_gray.svg"
import nextSliderIcon from "images/next_slider_incon_gray.svg"
import "./News.scss"
import { useFormatter } from "../../../helpers/i18n"
import { news, newsVN } from "data/news"

export default function News() {
  const { __, currentLocale } = useFormatter()

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const renderNews = () => {
    const _news = currentLocale === "vi" ? newsVN : news

    return _news.map((item, index) => {
      return (
        <div className="EePOugTXGX" key={index}>
          <NewsCard data={item} />
        </div>
      )
    })
  }
  return (
    <>
      <div className="WdZHPaJUsL">
        <ContainerMain>
          <div className="section-title svoVqdrGDg">
            {__({ defaultMessage: "In The News" })}
          </div>
          <Content>
            <Slider
              className="bYrVNJdzkW"
              style={{ marginRight: -24 }}
              {...settings}
              autoplay={true}
            >
              {renderNews()}
            </Slider>
          </Content>
        </ContainerMain>
      </div>
    </>
  )
}

const NewsCard = ({ data }) => {
  const { __ } = useFormatter()
  return (
    <div className="zGDsKXiVOp-home">
      <div className="QypwTPNqoS">
        <div className="img_wrapper">
          <img
            alt="image"
            className="SJVEIkshKX"
            src={data.image}
            loading="lazy"
          />
        </div>
      </div>
      <div className="BSxRUWALKW">
        <div className="kQIUVgbbKj">
          <div className="FXIQCzUdUS">{"Media"}</div>
        </div>
        <div className="mWlYDoscNs">{data.timer}</div>
      </div>
      <a
        className="OKZiCgTrQx"
        href={data.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {data.title}
      </a>
      <div className="IpbDjzWUoq">{data.description}</div>
      <div className="LeEIMdbPao">
        <a
          href={data.href}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-learn-more background-red"
        >
          {__({ defaultMessage: "Read more" })}
        </a>
      </div>
    </div>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={nextSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", height: 15 }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={prevSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", height: 15 }}
      onClick={onClick}
    />
  )
}
